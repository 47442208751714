.icon-sanitka410 {
  background: url("https://img2.cncenter.cz/images/moje-zdravi/dist/non-sprite/sanitka410.png") no-repeat;
  height: 142px;
  width: 347px;
}

.icon-mz_placeholder {
  background: url("https://img2.cncenter.cz/images/moje-zdravi/dist/non-sprite/mz_placeholder.png") no-repeat;
  height: 288px;
  width: 498px;
}

.icon-sanitka {
  background: url("https://img2.cncenter.cz/images/moje-zdravi/dist/non-sprite/sanitka.png") no-repeat;
  height: 142px;
  width: 347px;
}
